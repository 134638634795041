import { isObject } from './utils';

class EmbedMessage {
  constructor(params) {
    this.type = params.type; // 消息类型
    this.code = params.code || 0; // 状态：0 成功
    this.msg = params.msg || ''; // 消息描述信息
    this.data = isObject(params.data) ? params.data : {}; // 消息体
  }
}
// 直播创建
export function liveCreate(event) {
  const body = new EmbedMessage({ type: 'embed_live_create', ...event });
  window.parent.postMessage(body, '*');
}

// 直播创建-取消
export function liveCreateCancel(event) {
  const body = new EmbedMessage({ type: 'embed_live_create_cancel', ...event });
  window.parent.postMessage(body, '*');
}

// 直播编辑
export function liveEdit(event) {
  const body = new EmbedMessage({ type: 'embed_live_edit', ...event });
  window.parent.postMessage(body, '*');
}

// 直播编辑-取消
export function liveEditCancel(event) {
  const body = new EmbedMessage({ type: 'embed_live_edit_cancel', ...event });
  window.parent.postMessage(body, '*');
}

// 点播创建
export function vodCreate(event) {
  const body = new EmbedMessage({ type: 'embed_vod_create', ...event });
  window.parent.postMessage(body, '*');
}

// 点播创建-取消
export function vodCreateCancel(event) {
  const body = new EmbedMessage({ type: 'embed_vod_create_cancel', ...event });
  window.parent.postMessage(body, '*');
}

// 点播编辑
export function vodEdit(event) {
  const body = new EmbedMessage({ type: 'embed_vod_edit', ...event });
  window.parent.postMessage(body, '*');
}

// 点播编辑-取消
export function vodEditCancel(event) {
  const body = new EmbedMessage({ type: 'embed_vod_edit_cancel', ...event });
  window.parent.postMessage(body, '*');
}

// 定时直播创建
export function timeCreate(event) {
  const body = new EmbedMessage({ type: 'embed_time_create', ...event });
  window.parent.postMessage(body, '*');
}

// 定时直播创建-取消
export function timeCreateCancel(event) {
  const body = new EmbedMessage({ type: 'embed_time_create_cancel', ...event });
  window.parent.postMessage(body, '*');
}

// 定时直播编辑
export function timeEdit(event) {
  const body = new EmbedMessage({ type: 'embed_time_edit', ...event });
  window.parent.postMessage(body, '*');
}

// 定时直播编辑-取消
export function timeEditCancel(event) {
  const body = new EmbedMessage({ type: 'embed_time_edit_cancel', ...event });
  window.parent.postMessage(body, '*');
}
