<template>
  <div class="moduleTutorialWrap">
    <div class="content">
      <p class="title">使用场景</p>
      <article class="valueItem">
        <p class="itemTitle"></p>
        <p class="itemValue">
          适用于数字大会、线上培训直播等场景，直播中使用此功能可实时地将说话内容转换成字幕，帮助观看的人们更好地理解内容，提升观看体验。
        </p>
      </article>
      <article class="valueItem">
        <p class="itemTitle"></p>
        <p class="itemValue">
          <strong>注意事项：</strong>
          <br />
          1.请在直播开始前设置好此功能。设置后，观看直播时观众将能看到字幕；
          <br />
          2.点播活动、分组讨论活动不支持实时字幕；
          <br />
          3.PC端和手机端因终端分辨率不同，字幕展示效果有一定差异。
          <br />
        </p>
      </article>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="less" scoped>
  .moduleTutorialWrap {
    background-image: url('../../common/images/subtitle_intro.png');
    background-size: cover;
    height: 500px;
  }
  .content {
    padding: 130px 0 0 464px;
    .title {
      width: 95px;
      height: 24px;
      background: #fb3d32;
      border-radius: 12px;
      color: #ffffff;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
    }
    .valueItem {
      .itemTitle {
        width: 33px;
        height: 3px;
        margin-top: 22px;
        // background-image: linear-gradient(to right, color-stop1, color-stop2);

        background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, #fb3b32 100%);
      }
      .itemValue {
        width: 280px;
        padding-top: 10px;
        font-size: 14px;
        font-weight: 0;
        color: #000000;
        line-height: 20px;
        strong {
          font-weight: 900;
        }
      }
    }
  }
</style>
